import React, { useState } from "react";
import { Container } from "reactstrap";
import "./FormsContact.css";
import { sendEmail } from "../../services/services";

export default function FormsContact() {
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  const handleSendEmail = async (e) => {
    e.preventDefault();
    const data = { name, email, phone, message };
    const response = await sendEmail(data);

    if (response) {
      alert("Mensaje enviado correctamente");
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
    } else {
      alert("Error al enviar el mensaje");
    }
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value.replace(/\D/g, "");
    if (input.length <= 10) {
      const formattedPhone = formatPhoneNumber(input);
      setPhone(formattedPhone);
      setPhoneError(input.length < 10 ? "Número incompleto" : "");
    }
  };

  const formatPhoneNumber = (number) => {
    const phoneNumber = number.replace(/\D/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  return (
    <Container fluid="true" className="form-container">
      <form className="contact-form" onSubmit={handleSendEmail}>
        <div className="form-title">Contáctanos</div>
        <div className="form-group">
          <input
            required
            autoComplete="off"
            type="text"
            id="name"
            name="name"
            value={name}
            className="form-input"
            onChange={(e) => setName(e.target.value)}
          />
          <label htmlFor="name" className="form-label">Nombre</label>
        </div>
        <div className="form-group">
          <input
            required
            autoComplete="off"
            name="email"
            type="email"
            value={email}
            id="email"
            className="form-input"
            onChange={(e) => setEmail(e.target.value)}
          />
          <label htmlFor="email" className="form-label">Correo</label>
        </div>
        <div className="form-group">
          <input
            required
            autoComplete="off"
            type="tel"
            id="phone"
            name="phone"
            className="form-input"
            value={phone}
            onChange={handlePhoneChange}
            maxLength={14}
          />
          <label htmlFor="phone" className="form-label">Número de contacto</label>
          {phoneError && <span className="error-message">{phoneError}</span>}
        </div>
        <div className="form-group">
          <textarea
            required
            cols={30}
            rows={1}
            value={message}
            name="message"
            id="message"
            className="form-textarea"
            onChange={(e) => setMessage(e.target.value)}
          />
          <label htmlFor="message" className="form-label">Mensaje</label>
        </div>
        <button className="cta" type="submit">
          <span>Enviar</span>
          <svg width="15px" height="10px" viewBox="0 0 13 10">
            <path d="M1,5 L11,5"></path>
            <polyline points="8 1 12 5 8 9"></polyline>
          </svg>
        </button>
      </form>
    </Container>
  );
}
